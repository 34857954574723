import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PreLoader from "../components/preLoader/PreLoader";
const Home = lazy(() => import("../pages/HomePage"));
const Config = () => {
  return (
    <Router>
      <Suspense fallback={<PreLoader />}>
        <Routes>
          {/* <------Regular Routes------> */}
          <Route path="/" element={<Home />}></Route>
        </Routes>
      </Suspense>
    </Router>
  );
};

export default Config;
